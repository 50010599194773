@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
  @font-face {
    font-family: "MontserratItalic";
    src: url(/src/assets/font/Montserrat-Italic-VariableFont_wght.ttf) format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    src: url(/src/assets/font/Montserrat-VariableFont_wght.ttf) format("truetype");
  }
}.button_hover:hover {
    gap: 20px;


}

.slider-container {
    position: relative;
    margin: auto;
  }
  


.lider_feedback_icon {
    font-size: 20px; 
    color: black; 
}
  .swiper-initialized{
    height: 100%;
  }
  .swiper-button-next{
    color: rgb(23, 64, 60) !important;
   border:1px solid rgb(23, 64, 60) ;
    padding: 20px;
    border-radius: 50%;
  }
.p-toast .p-toast-message.p-toast-message-error  ,.p-toast .p-toast-message.p-toast-message-success{
  background-color: rgba(244, 67, 54, 0.7); 
  color: white; 
  border-radius: 8px; 
  backdrop-filter: blur(10px); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px; 
}
.p-toast .p-toast-message.p-toast-message-success {
  background-color: #4caf50 !important; 
}

.p-menuitem-content{
  margin: 0 20px;
}
.p-megamenu-col-4{
  width: 100%;
}
.p-megamenu-grid{
  display: flex;
  flex-direction: column;
  background: white;
  padding:10px 20px;
  width: 300px;
  margin-top: 20px;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 12px 10px;
  padding-top: 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

label {

  font-size: 12px;
 color: rgb(23, 64, 60); 
  transition: all 0.3s ease;
  pointer-events: none;
}

input:focus {
  border-color: rgb(23, 64, 60); 
}

input:focus + label,
input:not(:placeholder-shown) + label {
  top: -10px; 
  left: 20px;
  padding: 0 10px;
  background-color: white;
  font-size: 12px;
  color: rgb(23, 64, 60); 
}

input:placeholder-shown + label {
  top: 12px;
  font-size: 12px;
  color: rgb(23, 64, 60); 
}
