
.signup label {
    position: absolute;
    top: 12px;
    left: 10px;
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .signup input:focus {
    border-color: rgb(23, 64, 60); 
  }
  
  .signup input:focus + label,
  input:not(:placeholder-shown) + label {
    top: -10px; 
    left: 20px;
    padding: 0 10px;
    background-color: white;
    font-size: 12px;
    color: rgb(23, 64, 60); 
  }
  
  .signup input:placeholder-shown + label {
    top: 12px;
    font-size: 16px;
    color: #aaa;
  }
  